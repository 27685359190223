'use client'
import { usePathname, redirect } from "next/navigation";

const NotFound = () => {
  const pathname = usePathname();
  const pathParts = pathname.split('/').filter(part => part !== '');

  const customRoutes = {
    '/': 'Inicio',
  };

  if (pathParts.length > 0 && !customRoutes[pathParts[0]]) {
    redirect('/');
  }


  return null
}

export default NotFound;
